import { GeoPosition } from './models/geo-position';

export function getPricingKey(fromCoords: GeoPosition, toCoords: GeoPosition) {
    return `${fromCoords.lat}:${fromCoords.lng}|${toCoords.lat}:${toCoords.lng}`;
}

export function getCoordsFromPricingKey(id: string): [GeoPosition, GeoPosition] {
    const [fromCoordsString, toCoordsString] = id.split('|');
    const [fromLat, fromLng] = fromCoordsString.split(':');
    const [toLat, toLng] = toCoordsString.split(':');

    return [
        {
            lat: Number(fromLat),
            lng: Number(fromLng),
        },
        {
            lat: Number(toLat),
            lng: Number(toLng),
        },
    ];
}
