import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PartnerPricingActions } from '../actions/partner-pricing.actions';
import { selectPartnerPricings, selectPricing } from '../selectors/partner-pricing.selector';
import { GeoPosition } from '../models/geo-position';

@Injectable({
    providedIn: 'root',
})
export class PartnerPricingFacade {
    pricings$ = this.store.select(selectPartnerPricings);
    isPricingLoaded$ = (fromCoords: GeoPosition, toCoords: GeoPosition) => this.store.select(selectPricing(fromCoords, toCoords));

    constructor(private store: Store) {}

    getPricing(fromCoords: GeoPosition, toCoords: GeoPosition): void {
        this.store.dispatch(PartnerPricingActions.getPricing({ fromCoords, toCoords }));
    }

    clear(): void {
        this.store.dispatch(PartnerPricingActions.clear());
    }
}
