import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PricingModel } from '../models/pricing.model';
import { HttpErrorResponse } from '@angular/common/http';
import { GeoPosition } from '../models/geo-position';

export const PartnerPricingActions = createActionGroup({
    source: 'PartnerPricing',
    events: {
        'Get Pricing': props<{ fromCoords: GeoPosition; toCoords: GeoPosition }>(),
        'Get Pricing Success': emptyProps(),
        Clear: emptyProps(),
    },
});

export const PartnerPricingApiActions = createActionGroup({
    source: 'PartnerPricing',
    events: {
        'Get Pricing API Start': props<{ fromCoords: GeoPosition; toCoords: GeoPosition }>(),
        'Get Pricing API Success': props<{ fromCoords: GeoPosition; toCoords: GeoPosition; payload: PricingModel[] }>(),
        'Get Pricing API Error': props<{ error: HttpErrorResponse }>(),
        Clear: emptyProps(),
    },
});
