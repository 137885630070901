import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PartnerPricingState } from '../reducers/partner-pricing.reducer';
import { PARTNER_PRICING_FEATURE_KEY } from '../keys';
import { getPricingKey } from '../pricing.helpers';
import { GeoPosition } from '../models/geo-position';

export const selectState = createFeatureSelector<PartnerPricingState>(PARTNER_PRICING_FEATURE_KEY);
export const selectPartnerPricings = createSelector(selectState, (state) => state.results);
export const selectPricing = (fromCoords: GeoPosition, toCoords: GeoPosition) =>
    createSelector(selectState, (state) => {
        const pricingKey = getPricingKey(fromCoords, toCoords);
        return state.results.find((result) => result.key === pricingKey);
    });
